 
/* Clases Propias */

.divcolumnasexternomisvinculaciones  {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin: 0px;
    padding: 10px;
    min-height: calc(100vh - 195px);
    max-height: calc(100vh - 195px);
/*    overflow: hidden;
    border-radius: 5px;
    border: 1px solid #e0e0e0;

*/
    width: calc(100% - 20px);
}

.divfilasinternomisvinculaciones  {
    display: flex;
    flex-direction: row;
    margin: 0px;
    padding: 0px;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;

}

.divtitulomisvinculaciones  {
    padding: 0px;
    margin: 0px;
    margin-left: 5px;
    width: 50%;
}

.parrafotitulomisvinculaciones {
    padding: 0px;
    margin: 0px;
    margin-top: 10px;
    font-family: 'Roboto';
    font-weight: bold;
}

/*  Iconos titulo*/
.divexternoiconosmisvinculaciones  {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding:0px;
    margin:0px;
    width: 50%;

}
.divinternoiconosmisvinculaciones  {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding:0px;
    margin:0px;
    background-color: white;
}

.divinternoiconosmisvinculaciones:hover {
    background-color: #f2f2f2 ;
 }
 
.iconomisvinculaciones  {
    display: flex;
    flex-direction: row;
    padding:0px;
    margin:0px;
    font-size:12px;
    color: blue;
 }


 .divtituloiconosmisvinculaciones  {
    padding: 0px;
    margin: 0px;
}

.parrafotituloiconosmisvinculaciones {
    padding: 0px;
    margin: 0px;
    margin-top: 12px;
    font-family: 'Roboto';
    font-size: 14px;
    margin-right: 5px;
}


/* Cards*/

 .divmisvinculacionescard {
    display: flex;
    flex-direction: column;
    width:95%;
    background: linear-gradient(45deg,  #fff 80%, #eeeeee 90%);
    color: gray    ;
    margin: 0px;
    padding: 5px;
    margin-top:15px;
/*    border: 1px solid rgb(0, 140, 255);
    border-radius: 5px;

*/
    border: 1px solid gainsboro;
    border-left: 3px solid rgb(0, 140, 255);
    border-top-right-radius: 5px;
    /*  background: linear-gradient(to right, #dbf5e5, #e0e0e0 40%, #fafafa);*/
}

.divmisvinculacionescard:nth-child(even){
    color:blue;
    background: linear-gradient(45deg,  #fff 80%, #eeeeee 90%);
 }

 .divmisvinculacionescardarriba {
    margin: 0px;
    padding: 0px;
    margin-bottom:5px;
    font-size:14px;
}

.divmisvinculacionescardabajo {
    margin: 0px;
    padding: 0px;
    font-size:14px;
}

.parrafocitamisvinculaciones {
    padding: 0px;
    margin: 0px;
}
.parrafotextomisvinculaciones {
    padding: 0px;
    margin: 0px;
}

/* concordancia*/
.divbuscarmisvinculaciones  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:0px;
    margin:0px;
    margin-bottom:5px;
    width: 100%;
    font-size:13px;
    border-bottom: 1px solid #e0e0e0;
}


.divinputbuscarmisvinculaciones  {
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
    padding:0px;
    width: 100%;
    margin:0px;
}

.inputbuscarmisvinculaciones {
    background-color: white;
    color: gray;
    border: 0;
    height: 30px;
    padding: 0 30px;
    margin: 0px;
    width: 70%; 
    font-size:13px;
    outline: none;
    border-radius: 25px;
    border: 1px solid #e0e0e0;
}

.diviconosbuscarmisvinculaciones  {
    display: flex;
    flex-direction: row;
    justify-content:left; 
    padding:0px;
    width: 29%;
    margin:0px;
}
.iconobuscarmisvinculaciones  {
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
    padding:0px;
    width: 30px;
    margin:0px;
    margin-left:5px;
    margin-right:5px;
}

/* Móviles en horizontal o tablets en vertical*/
@media (max-width: 667px) { 

    .divcolumnasexternomisvinculaciones  {
        min-height: calc(100vh - 315px);
        max-height: calc(100vh - 315px);
    }

}

.ventanamodalcrearministerio {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y:auto;
    background-color: white;
    border: 1px solid;
    border-width: 1px;
    border-color: black;
    box-shadow:3px 3px 3px black;
    padding: 0px;
    width: 50%;
/*    margin-left:5px;
    margin-Top:10px;*/
 }

 /*ventanamodaltrescientos {
    position: 'fixed';
    top:'50%';
    left: '50%';
    transform: 'translate(-50%; -50%)';
    overflowY:'auto';
    backgroundColor: 'white';
    borderStyle:'solid';
    borderWidth: '1px';
    borderColor: 'black';
    boxShadow:"10px 5px 5px black";
    padding: "10px";
    width: '300px';
    marginLeft:'10px';
    marginTop:'10px';
 },*/

 .divpadrecrearministerio  {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,0.7343312324929971) 35%, rgba(0,212,255,1) 100%);
    padding: 0px;
    margin: 0px;
/*  height: 100vh;*/
    width: 100%;
/*  overflow: hidden;*/
}
 
.divhijocrearministerio  {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-Top: 20px;
    margin-bottom: 20px;
    padding: 0px;
    background-color: white;
    color: red;
/*    font-size:12px;
    height:100%;*/
    width: 95%;
}

.divtitulocrearministerio  {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    margin:0px;
    padding:0px;
    margin-top:10px;
    width: 90%;
    height: 50px;
    background-color: #dbf5e5;
    color: black;
    font-family:'Roboto';
    font-size:16px;
/*    text-align:center;*/
}

.htitulocrearministerio {
    margin:0px;
    padding:0px;
}

.divfilasexternoclosecrearministerio  {
    display: flex;
    flex-direction: row;
    padding:0px;
    width: 90%;
    padding:0px;
    margin:0px;
}

.divfilasinternoleftclosecrearministerio  {
    display: flex;
    flex-direction: row;
    align-items: flex-start; 
    justify-content: flex-start;
    padding:0px;
    width: 20%;
    padding:0px;
    margin:0px;
}
.divfilainternocenterclosecrearministerio  {
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
    padding:0px;
    width: 60%;
    padding:0px;
    margin:0px;
    color: black;
    font-family:'Roboto';
}

.divfilasinternorightclosecrearministerio  {
    display: flex;
    flex-direction: row;
    align-items: flex-end; 
    justify-content:flex-end;
    padding:0px;
    width: 20%;
    padding:0px;
    margin:0px;
}

.divbienvenidocrearministerio  {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    margin:0px;
    padding:0px;
    margin-top:30px;
    width: 90%;
    color: black;
    font-family:'Roboto';
/*   font-size:16px;*/
}

.divformcrearministerio {
    display: flex;
    flex-direction:column;
/*    align-items:center;*/
    margin:0px;
    padding:0px;
    margin-top:5px;
    width:100%; 
    color: "red";
}

/*  Crear cuenta*/
.divfilasleftcrearministerio  {
    display: flex;
    flex-direction: row;
    padding:0px;
    width: 100%;
    margin:0px;
    padding:0px;
    margin-top:5px;
    margin-bottom:5px;
    height: 30px;
}

.divcolumnasnormalcrearministerio  {
    display: flex;
    flex-direction: column;
    padding:0px;
    width: 100%;
    margin:0px;
    padding:0px;
    margin-top:20px;
    margin-bottom:5px;
}

.divcolumnascenternormalcrearministerio {
    display: flex;
    flex-direction: column;
    justify-content: "center";
    align-items:center;
    margin:0px;
    padding:0px;
    width: 100%;
}

.parrafoerrorcrearministerio {
    color: red;
    font-size: 13px;
    padding: 0px;
    margin: 0px;
}

.parrafonormalcrearministerio {
    color: black;
    font-size: 13px;
    padding: 0px;
    margin: 0px;
}

.etiquetacrearministerio {
    color: black;
    font-size: 13px;
    padding: 0px;
    margin: 0px;
    margin-top:8px;
    margin-right:10px;
}

.campocrearministerio {
    display:flex;
    flex: 1;
    border: 1px solid rgb(204, 206, 207);
    border-radius: 5px;
    outline: none;
    background-color: #fafafa;
    color: blue;
    padding: 0px;
    margin: 0px;
    width: 90%;
    padding-left: 5px;
    height: 30px;
    font-size:14px;
}
::placeholder{
    color:#bdbdbd;
    font-size:13px;
}

.campotextofechacrearministerio1501  {
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background-color: #fafafa;
    color: blue;
    padding: 0px;
    margin: 0px;
    width: 150px;
    height: 30px;
    font-size: 14px;
    outline: none;
}

.camposelectcrearministerio  {
    display:flex;
    flex: 1;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background-color: #fafafa;
    color: blue;
    padding: 0px;
    margin: 0px;
    width:90%;
    height:30px;
    font-size:14px;
    outline: none;
}

.camposelectplaceholdercrearministerio  {
    display:flex;
    flex: 1;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background-color: #fafafa;
    color:#bdbdbd;
    font-size:13px;
    padding: 0px;
    margin: 0px;
    width:90%;
    height:30px;
    outline: none;
}

.botoniconobackcrearministerio {
    color: blue;
    padding: 0px;
    height: 20px;
    width: 20px;
}

.contenedorbotoniconobackcrearministerio  {
    height: 10px;
    width: 10px;
    margin: 0px;
}

.separadorcrearministerio {
    padding: 0px;
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 5px;
    width: 100%;
    background: radial-gradient(circle, rgba(148,187,233,1) 0%, blue 100%);
/*    border: 1px solid blue;*/
    border-radius: 25px;
}

.separadorgriscrearministerio {
    padding: 0px;
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 5px;
    width: 100%;
    background: radial-gradient(circle, rgb(217, 221, 226, 1) 0%, gray 100%);
/*    border: 1px solid blue;*/
    border-radius: 25px;
}

.botoncrearministerio {
    background: rgb(124, 172, 235);
    border: 0;
    border-radius: 5px;
    color: white;
    height: 40px;
    padding: 0 30px;
    margin: 0px;
    margin-top: 5px;
    margin-bottom:5px;
    width:100%; 
    font-weight:bold;
    font-size:16px;
 
}

.botoncrearministerio:hover {
  background: #1a237e;
}

.botonlinkcrearministerio {
    background-color: white;
    color: gray;
    border: 0;
    height: 40px;
    padding: 0 30px;
    margin: 0px;
    margin-top: 5px;
    margin-bottom:5px;
    width:200px; 
    font-weight:bold;
    font-size:16px;
    outline: none;
}

.botonlinkcrearministerio:hover {
  color: #0408f5;
  text-decoration: underline;
  text-decoration-color: #9e9e9e;
}

/* medidas de dispositivos   */
/* Tablets en horizontal y escritorios normales*/
@media (min-width: 868px) { 
    .ventanamodalcrearministerio  {
        width: 50%;
    }

}


/* Móviles en horizontal o tablets en vertical*/
@media (min-width: 668px) and (max-width: 867px) { 

    .ventanamodalcrearministerio  {
        width: 70%;
    }

    .divhijocrearministerio  {
        margin-Top: 10px;
        margin-bottom: 10px;
    }

}


@media (min-width: 481px) and (max-width: 667px) { 
    .ventanamodalcrearministerio  {
        width: 90%;
    }

}


/* Móviles en vertical*/
@media (max-width: 480px) { 

    .ventanamodalcrearministerio  {
        width: 95%;
    }
    .divhijocrearministerio  {
        margin-Top: 10px;
        margin-bottom: 10px;
    }


}
