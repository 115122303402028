@import url('https://fonts.googleapis.com/css?family=Kalam'); 
@import url('https://fonts.googleapis.com/css?family=Shadows+Into+Light'); 
/* Clases Propias */

.divfilasexternodevocional  {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin: 0px;
    padding: 10px;
    min-height: calc(100vh - 195px);
    max-height: calc(100vh - 195px);
/*    overflow: hidden;
    border-radius: 5px;
    border: 1px solid #e0e0e0;

*/
    width: calc(100% - 20px);
}

/* Cards*/

 .divdevocionalcard {
    display: flex;
    flex-direction: column;
    width:100%;
    color:black;
    margin: 0px;
    padding: 0px;
    margin-top:15px;
 /*  background: linear-gradient(to right, #dbf5e5, #e0e0e0 40%, #fafafa);*/

}

 .divdevocionalcardarriba {
    margin: 0px;
    padding: 0px;
    margin-bottom:5px;
    font-size:14px;

}

.divdevocionalcardmedio {
    margin: 0px;
    padding: 0px;
}

.divdevocionalcardabajo {
    margin: 0px;
    padding: 0px;
    font-size:14px;
}

.parrafocita2 {
    padding: 0px;
    margin: 0px;
}
.parrafotexto2 {
    padding: 0px;
    margin: 0px;
}

.imagendevocionalmedio {
    margin: 0px;
    padding: 0px;
    width: calc(100% -20px);
    height: 300px;
}


/* audio*/
.divaudiodevocional  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:0px;
    margin:0px;
    margin-bottom:5px;
    width: 100%;
    font-size:13px;
}

.divparrafotituloaudiodevocional  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:0px;
    width: 100%;
    margin:0px;
    margin-bottom:5px;
}
.parrafotituloaudiodevocional {
    padding: 0px;
    margin: 0px;
    margin-left: 10px;
    font-family: 'Roboto';
    font-weight: bold;
}

.audiodevocional  {
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
    padding:0px;
    width: 100%;
    margin:0px;
}

/* Promesa*/

.divpromesadevocional  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:15px;
    margin:0px;
    margin-bottom:5px;
    width: calc(100% - 30px);
    font-size:20px;
    background: white;
    background: radial-gradient(circle, rgb(252, 252, 252,1) 0%, rgba(148,187,233,1) 100%);
}

.parrafopromesadevocional {
    padding: 0px;
    margin: 0px;
    margin-left: 10px;
    font-family: 'Kalam';
    font-weight: bold;
}


/* Lectura devocional*/
.divlecturadevocional  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:0px;
    margin:0px;
    margin-top:30px;
    margin-bottom:5px;
    width: 100%;
    font-size:13px;
}

.divtitulolecturadevocional  {
    padding:0px;
    width: 100%;
    margin:0px;
}

.titulolecturadevocional {
    padding: 0px;
    margin: 0px;
    margin-left: 10px;
    font-family: 'Roboto';
    font-weight: bold;
}


.divtextolecturadevocional  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:10px;
    margin:0px;
    margin-top:5px;
    margin-bottom:5px;
    width: calc(100% - 20px);
    font-size:24px;
    background: rgb(255, 241, 118);
    background: radial-gradient(circle, rgba(255, 241, 118,1) 0%, rgba(148,187,233,1) 100%);
}

.textolecturadevocional {
    padding: 0px;
    margin: 0px;
    margin-left: 10px;
    font-family: 'Shadows Into Light';
    font-weight: bold;
}


/* Móviles en horizontal o tablets en vertical*/
@media (max-width: 667px) { 

    .divfilasexternodevocional  {
        min-height: calc(100vh - 315px);
        max-height: calc(100vh - 315px);
    }

}


